import React from "react"
import Layout from '../../components/layout';
import styles from '../../components/index.module.css'
import Helmet from 'react-helmet';
import Img from 'gatsby-image';
import { useStaticQuery, graphql, Link } from 'gatsby';

export default ()=>{
  const data = useStaticQuery(graphql`{
  allFile(filter: {relativeDirectory: {eq: ""}}) {
    nodes {
      childImageSharp {
        fluid(maxWidth: 800) {
          originalName
          ...GatsbyImageSharpFluid
          }
      }
  }
}
}`)

let images = {};

data.allFile.nodes.forEach((item, i) => {
  images[item.childImageSharp.fluid.originalName] = item.childImageSharp.fluid;
});


  return (
    <Layout>
    <Helmet>
    <title>Namnlappar - hög kvalitet, prisvärda, snabb leverans</title>
    <meta name="description" content="Vi har testat ett par typer av prisvärda namnalappar som håller hög kvalitet och levererades snabbt efter beställning." />
    </Helmet>
    <div>
      <div className={ styles.productCategoryMainDescription }>
        <div className={ styles.productCategoryMainDescriptionHeader }>
        <h1> Prisvärda namnlappar med hög kvalitet! </h1>
          <p>
          Vi har testat prisvärda namnlappar, såväl klisternamnlappar som stryklappar. Redan en månad efter att vår ettåring började på dagis var det dags för första vanten att komma bort, den var återfunnen redan dagen efter! Att veta att namnlappen sitter där den ska var plötsligt värt varje investerad krona. Det kostade nämligen en krona och femtio öre att märka vanten, helt enkelt billiga namnlappar. Så redan efter en månad hade ett ark med klisternamnlapparna betalat sig för vår del.
          <blockquote cite="https://www.dagistips.se/namnlappar">Vi har till och med sett klisteretiketter sitta kvar i kläder som hunnit passera tre syskonbarn före att vi fått ärva dem.
          </blockquote>
          Vi har testat två typer av billiga namnlappar från Filur Namnlappar SE
          <a href="https://www.namnlappar.se/namnlappar" rel="nofollow"> Här beställer du de testade lapparna. </a>
          <Img style={{'max-width': '500px'}} fluid={images["namnlappar.jpg"]} alt="Billiga stryknamnlappar och klisterlappar"></Img>
          <ul className={styles.productCategoryTipList}>
          <h3>Därför skall du välja en billig namnlapp med hög kvalitet</h3>
          <li>Låg kostnad per plagg som märks, under 2 kronor även för namnlappar med kända figurer och varumärken</li>
          <li>Tål att tvättas många gånger i temperatur upp till 60 grader.</li>
          <li>Du kan märka alla typer av kläder, sportutrustning, leksaker och teknikprylar!</li>
          </ul>
          <Link to="/namnlappar/test-av-filur-klisternamnlappar">Vårt test av Filur Klisternamnlappar</Link>
          <br></br>
          <Link to="/namnlappar/test-av-filur-stryknamnlappar">Vårt test av Filur Stryknamnlappar</Link>
          </p>
          <p>
          Att spara på allt för billiga namnlapparna är helt enkelt inte värt risken! Jämför nedan så ser du hur snabbt namnlapparna betalar sig. En overall motsvarar 600 märkningar du kunde ha köpt. Så beroende på vilket plagg som återfinns har du såklart olika snabb återbetalning av lapparna. Det enda som är garanterat är att några plagg kommer på villovägar och det kommer att ske oftare än vad du tänker dig. Så se till att göra dina inköp av namnlappar före dit barn börjar på förskolan!
          </p>
          <table>
            <th colspan="6">Kostnad för märkning i förhållande till klädkostnad
            </th>
            <tr>
              <td>Plagg</td>
              <td>Kostnad</td>
              <td>Antal etiketter</td>
              <td>Märkningskostnad</td>
              <td>%-av plagget</td>
              <td>Återfunnet plagg motsvarar x antal lappar</td>
            </tr>
            <tr>
              <td>Vantar</td>
              <td>150kr</td>
              <td>2st</td>
              <td>3.0kr</td>
              <td>2%</td>
              <td>100st</td>
            </tr>
            <tr className={styles.emphasisRow}>
              <td>Overall</td>
              <td>900kr</td>
              <td>1st</td>
              <td>1.5kr</td>
              <td>0.16%</td>
              <td>600st</td>
            </tr>
            <tr>
              <td>T-shirt</td>
              <td>150kr</td>
              <td>1st</td>
              <td>1.5kr</td>
              <td>1%</td>
              <td>100st</td>
            </tr>
            <tr>
              <td>Vinterstövlar</td>
              <td>600kr</td>
              <td>2st</td>
              <td>3.0kr</td>
              <td>0.5%</td>
              <td>400st</td>
            </tr>
            <tr>
              <td>Byxor</td>
              <td>189kr</td>
              <td>1st</td>
              <td>1.5kr</td>
              <td>0.8%</td>
              <td>126st</td>
            </tr>
          </table>
        </div>
        <h3>Olika anledningar att använda namnlappar</h3>
        <p>Det finns många anledning att använda namnlappar, att återfå borttappade kläder eller rättare sagt att helt undvika att de kommer bort
        är väl den främsta. Med korrekt märkning blir det lätt att särskilja dina från andras kläder, om du har glömt att märka ett plagg och det upphittade plagget är märkt kan du i alla fall konstatera att det inte är ditt plagg. Detta underlättar mycket när det är olika föräldrar som lämnar på förskolan till exempel.</p>
        <p className={styles.sponsor}>
          Inlägget innehåller reklam genom annonslänkar för Filur Namnlappar SE
        </p>
        <a href="https://www.namnlappar.se/namnlappar" rel="nofollow"> Här hittar du billiga namnlappar vi har funktionstestat.</a>
        <div className={ styles.productCategoryMainDescriptionIntro }>
        </div>
      </div>
    </div>
    <script type="text/javascript" src="https://cdn.adt532.com/atag.js?as=1556616723" charset="UTF-8"></script>
    </Layout>
  )
}
